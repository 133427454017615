module.exports = {
    init: () => {
        /*
            reveals password fields by changing input type to text
        */
        const passwordFields = document.getElementsByClassName("reveal_password_field");
        if (passwordFields) {
            passwordFields.forEach((pwordField) => {
                let revealBtns = pwordField.parentElement.getElementsByClassName("reveal_password_button");
                revealBtns.forEach((btn) => {
                    btn.addEventListener("click", (e) => {
                        if (pwordField.type === "password") {
                            pwordField.type = "text";
                        } else {
                            pwordField.type = "password";
                        }
                    });
                });
            });
        };
    }
};
