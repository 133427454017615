module.exports = {
    // check whether the `article` element has a scrollbar,if so
    // add padding to the `.user_context_actions` element,
    // to make centred, contained elements line up horizontally
    init: () => {
        class MeasureScrollbar {
            constructor () {
                this.scrollbarWidth = 0;
                this.measureScrollbarWidth();
            }

            measureScrollbarWidth () {
                // add temporary `div` element to document
                let temporaryDiv = document.createElement("div");
                // make `div` element scrollable
                temporaryDiv.style.overflow = "scroll";
                // append `div` element to `body` element
                document.body.appendChild(temporaryDiv);
                // measure inner width of `div` element
                this.scrollbarWidth = temporaryDiv.offsetWidth - temporaryDiv.clientWidth;
                // remove temporary `div` element
                document.body.removeChild(temporaryDiv);
            }

            get width () {
                return this.scrollbarWidth;
            }
        }
        // get the `article` element
        const article = document.querySelector("#alerts_and_content_and_form");
        if (article) {
            // store the height of the `article` element
            const articleHeight        = article.clientHeight;
            // store the height of the `article` element's content
            const articleContentHeight = article.scrollHeight;
            // check whether `article` element has a scrollbar
            if (articleHeight < articleContentHeight) {
                // if so, store the scrollbar width in pixels
                const offsetAmount = new MeasureScrollbar().width;
                // get the `.user_context_actions` element
                const userContextActions = document.querySelector(".user_context_actions");
                if (userContextActions) {
                    // add sidebar width as padding-right to the `.user_context_actions` element
                    userContextActions.style.paddingRight = offsetAmount + "px";
                }
            }
        }
    },
};
