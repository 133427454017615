module.exports = {
    // configure Stripe checkout and ensure license terms
    // are agreed to before submission
    init: () => {
        /* global Stripe */
        let stripeElement = document.getElementById("stripe-info");
        if (stripeElement) {
            let stripeInfo = JSON.parse(stripeElement.textContent);
            let handler = Stripe(stripeInfo.stripe_pk).redirectToCheckout({
                sessionId: stripeInfo.session_id,
            });
            document.getElementById("purchasePassesButton").addEventListener("click", function () {
                handler.open({
                    name: "Ping!",
                    currency: "gbp",
                    amount: Number(stripeInfo.stripe_price),
                });
            });
            window.addEventListener("popstate", function () {
                handler.close();
            });
        }
    },
};
