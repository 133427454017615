module.exports = {
    /**
     * copy_package_assets
     *
     * Enables the copy to clipboard of text and assets from central package detail page.
     *
     * Requires a `.clipboard_copy_content` class element, containing the content to be copied,
     * as well as a sibling `.clipboard_copy_emitter` class element to fire the copy event.
     * Optionally include `.clipboard_copy_html` to allow copying innerHTML of element.
     *
     */
    init: () => {
        const COPYRIGHT_MSG = "This content is licensed via Ping! and is subject to our terms and conditions.";

        function createCopyrightElem () {
            let copyright = document.createElement("p");
            copyright.innerText = COPYRIGHT_MSG;
            return copyright;
        }

        function selectHTML (content) {
            let el = document.createElement("textarea");
            el.setAttribute("type", "hidden");
            el.value = `<p>${COPYRIGHT_MSG}</p> ${content.innerHTML}`;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            el.remove();
        };

        function selectTextOrMedia (content) {
            let copyright = createCopyrightElem();
            content.prepend(copyright);
            let range = document.createRange();
            range.selectNode(content);
            window.getSelection().addRange(range);
            document.execCommand("copy");
            window.getSelection().removeAllRanges();
            content.removeChild(copyright);
        }

        function selectMedia (content) {
            let range = document.createRange();
            range.selectNode(content);
            window.getSelection().addRange(range);
            document.execCommand("copy");
            window.getSelection().removeAllRanges();
        }

        function showBtnCopied (btn) {
            let initialText = btn.innerText;
            btn.innerText = "Copied!";
            setTimeout(() => {
                btn.innerText = initialText;
            }, 4000);
        }

        function clickPackageUsed () {
            let pu = document.querySelector("#id_package_used");
            if (pu && !pu.checked) {
                pu.checked = true;
                pu.focus();
                setTimeout(() => { pu.dispatchEvent(new Event("click")); }, 1000);
            }
        }

        const copyBtns = document.querySelectorAll(".clipboard_copy_emitter");
        copyBtns.forEach((copyBtn) => {
            copyBtn.addEventListener("click", () => {
                let content = copyBtn.parentElement.querySelector(".clipboard_copy_content");

                if (content.classList.contains("clipboard_copy_html")) {
                    selectHTML(content);
                } else if (content.classList.contains("clipboard_copy_image")) {
                    console.log("guff");
                    selectMedia(content);
                } else {
                    selectTextOrMedia(content);
                }
                showBtnCopied(
                    copyBtn.querySelector(".package_detail__assets_item_details_anchor_text")
                );
                clickPackageUsed();
            });
        });

        // copy entire package
    const copyStories = document.querySelectorAll(".copy_story");

    copyStories.forEach(function(copyStory) {
      copyStory.addEventListener("click", () => {
        // create copyright elem
        let copyright = createCopyrightElem();
        // setup temp div for copying from, adding copyright to div
        let div = document.createElement("div");
        div.setAttribute("class", "temp");
        div.prepend(copyright);
        document.body.appendChild(div);

        // clear and setup Ranges
        window.getSelection().removeAllRanges();
        let range = document.createRange();

        copyBtns.forEach((copyBtn) => {
          let content = copyBtn.parentElement.querySelector(".clipboard_copy_content");
          // get either html or text to be copied
          if (content.classList.contains("clipboard_copy_html")) {
            let p = document.createElement("p");
            div.appendChild(p);
            p.innerText = content.innerHTML;
          } else {
            div.innerHTML += content.innerHTML;
          }
        });

        // copy
        range.selectNode(div);
        window.getSelection().addRange(range);
        document.execCommand("copy");

        // clean up, update button and package_used
                document.querySelectorAll(".temp").forEach((elem) => elem.remove());
        showBtnCopied(copyStory.querySelector(".copy_story__summary_text"));
        clickPackageUsed();
      });
    });

    }
};
