module.exports = {
    // copy authentication token id to the clipboard
    // when user interacts with the copy button
    init: () => {
        const UUIDID       = "global_header__uuid";
        const UUID         = document.getElementById(UUIDID);
        const copyAnchorID = "uuid__copy_anchor";
        const copyAnchor   = document.getElementById(copyAnchorID);
        function copyToken () {
            let range = document.createRange();
            range.selectNode(UUID);
            window.getSelection().addRange(range);
            document.execCommand("copy");
            window.getSelection().removeAllRanges();
            const initialButtonText = copyAnchor.innerText;
            copyAnchor.innerText = "Copied!";
            setTimeout(() => {
                copyAnchor.innerText = initialButtonText;
            }, 4000);
        }
        if (UUID && copyAnchor) {
            copyAnchor.onclick = copyToken;
        }
    },
};
