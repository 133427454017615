"use strict";
/*
    allow enchanced focus detection
*/
const KeyboardFocus = require("./modules/keyboard_focus");
KeyboardFocus.init();
/*
    polyfill to enable us to use forEach on node lists in IE11
*/
const ForEachPolyfill = require("./modules/foreach_polyfill");
ForEachPolyfill.init();
/*
    interpolate between minimum ratio & maximum ratio,
    based on current viewport width
*/
const TypeRatioInterpolation = require("./modules/type_ratio_interpolation");
TypeRatioInterpolation.init();
/*
    compensate for width of scrollbar in article element
*/
const ScrollbarCompensate = require("./modules/scrollbar_compensate");
ScrollbarCompensate.init();
/*
    copy uuid to the clipboard
    when user interacts with the copy achor
*/
const CopyUUID = require("./modules/copy_uuid");
CopyUUID.init();
/*
    close UUID details element when user interacts with
    anything outside of the element
*/
const UUIDOverlay = require("./modules/uuid_overlay");
UUIDOverlay.init();
/*
    toggle display of account options navigation list
    on interaction with the account options button element
*/
const AccountOptionsToggle = require("./modules/account_options_toggle");
AccountOptionsToggle.init();
/*

    when user interacts with details element, close
    any open details elements other than the target element
*/
const DetailsElementsToggle = require("./modules/details_elements_toggle");
DetailsElementsToggle.init();
/*
    toggle display of tag list item content
    on interaction with the tag list item button element
*/

const TagListItemToggle = require("./modules/tag_list_item_toggle");
TagListItemToggle.init();
/*
    toggle display of user context actions content
    on interaction with the user context actions button element
*/
const UserContextActionsToggle = require("./modules/user_context_actions_toggle");
UserContextActionsToggle.init();
/*
    copy authentication token id to the clipboard
    when user interacts with the copy button
*/
const CopyAuthenticationToken = require("./modules/copy_authentication_token");
CopyAuthenticationToken.init();
/*
    give each alert element a `display` value of `none`
    when user interacts with that alert's close button
*/
const AlertDismiss = require("./modules/alert_dismiss");
AlertDismiss.init();
/*
    toggle display of element based on the value of a seperate trigger element
*/
const toggleElem = require("./modules/toggle_elem");
toggleElem.init();

/*
    initialise google analytics
*/
const GoogleAnalytics = require("./modules/google-analytics_central");
GoogleAnalytics.init();

/*
    initialise stripe configuration
*/
const configureStripeCheckout = require("./modules/configure_stripe_checkout");
configureStripeCheckout.init();

/*
    initialise clipboard for copy & paste
*/
const clipboard = require("./modules/copy_package_assets");
clipboard.init();

/*
    reveal password fields on click
*/
const revealPassword = require("./modules/reveal_password");
revealPassword.init();

const downloadAllImages = require("./modules/download_package_images");
downloadAllImages.init();
