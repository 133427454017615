module.exports = {
    // toggle display of account options navigation list
    // on interaction with the account options button element
    init: () => {
        const accountOptionsButtonClass  = ".global_navigation__account_options_menu_toggle--is_in_div_element";
        const accountOptionsButton       = document.querySelector(accountOptionsButtonClass);
        const accountOptionsNavListClass = ".global_navigation__account_options_menu_list--is_in_div_element";
        const accountOptionsNavList      = document.querySelector(accountOptionsNavListClass);

        const menuNavListClass = ".global_navigation__menu";
        const menuNavList      = document.querySelector(menuNavListClass);

        if (accountOptionsButton) {
            accountOptionsButton.onclick = () => {
                if (!accountOptionsNavList.hasAttribute("open")) {
                    accountOptionsNavList.setAttribute("open", "");
                    // Hide menu if opening account options
                    if (menuNavList.hasAttribute("open")) {
                        menuNavList.removeAttribute("open");
                    }
                } else {
                    accountOptionsNavList.removeAttribute("open");
                }
            };
        }
    },
};
