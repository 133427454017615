module.exports = {
    // toggle display of tag list item content
    // on interaction with the tag list item button element
    init: () => {
        // get all tag list item elements
        const listItems = Array.from(document.getElementsByClassName("tag_list__item"));
        // if there are tag list item elements
        if (listItems) {
            /*
                expand and contract tag list item content on
                user interaction with tag list item's button
            */
            (() => {
                // for each of these button elements
                listItems.forEach((listItem) => {
                    // get the corresponding `button` element which toggles view of the content
                    const buttonElement  = listItem.querySelector(".tag_list__expand_button");
                    // get the corresponding `button` element's text content
                    const buttonText     = buttonElement.querySelector(".tag_list__expand_button__label");
                    // listen for click on the button element
                    buttonElement.onclick = () => {
                        const expandAllButton = document.querySelector("#tag_list__expand_all");
                        const collapseAllButton = document.querySelector("#tag_list__collapse_all");
                        // on click, toggle the visible state of the corresponding
                        // content `div` element, and change it's text
                        if (!listItem.hasAttribute("open")) {
                            listItem.setAttribute("open", "");
                            buttonText.innerText = "Collapse";
                            collapseAllButton.classList.replace("button--disabled", "button--secondary_action");
                        } else {
                            listItem.removeAttribute("open");
                            buttonText.innerText = "Expand";
                            expandAllButton.classList.replace("button--disabled", "button--secondary_action");
                        }
                    };
                });
            })();
            /*
                collapse all tag list items' content on
                user interaction with collapse all button
            */
            (() => {
                // get collapse all button
                const collapseAllButton = document.querySelector("#tag_list__collapse_all");
                // if there is a collapse all button
                if (collapseAllButton) {
                    // on click of collapse all button,
                    collapseAllButton.onclick = () => {
                        // loop over list items
                        listItems.forEach((listItem) => {
                            // check whether they have an open attribute
                            if (listItem.hasAttribute("open")) {
                                // if so, remove the open attribute
                                listItem.removeAttribute("open");
                                // get the text of the visibility toggle button
                                const buttonText = listItem.querySelector(".tag_list__expand_button__label");
                                // and change the text to `expand`
                                buttonText.innerText = "Expand";
                            }
                        });
                        // disable collapseAllButton and enable expandAllButton
                        collapseAllButton.classList.replace("button--secondary_action", "button--disabled");
                        const expandAllButton = document.querySelector("#tag_list__expand_all");
                        if (expandAllButton) {
                            expandAllButton.classList.replace("button--disabled", "button--secondary_action");
                        }
                    };
                }
            })();
            /*
                expand all tag list items' content on
                user interaction with expand all button
            */
            (() => {
                // get expand all button
                const expandAllButton = document.querySelector("#tag_list__expand_all");
                // if there is an expand all button
                if (expandAllButton) {
                    // on click of expand all button
                    expandAllButton.onclick = () => {
                        // loop over list items
                        listItems.forEach((listItem) => {
                            // check whether listItem has an open attribute
                            if (!listItem.hasAttribute("open")) {
                                // if not, add the open attribute
                                listItem.setAttribute("open", "");
                                // get the text of the visibility toggle button
                                const buttonText = listItem.querySelector(".tag_list__expand_button__label");
                                // and change the text to `collapse`
                                buttonText.innerText = "Collapse";
                            }
                        });
                        // disable expandAllButton & enable collapseAllButton
                        expandAllButton.classList.replace("button--secondary_action", "button--disabled");
                        const collapseAllButton = document.querySelector("#tag_list__collapse_all");
                        if (collapseAllButton) {
                            collapseAllButton.classList.replace("button--disabled", "button--secondary_action");
                        }
                    };
                }
            })();
        }
    },
};
