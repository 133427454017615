module.exports = {
    init: () => {
        /*
            toggle display of `#user_context_actions__content` `div` element
            when user interacts with `#user_context_actions__content_toggle`
            `button` element (applies only on small viewports due to CSS rules)
        */
        // get `button` element
        const buttonElement  = document.getElementById("user_context_actions__content_toggle");
        // if `button` element is present
        if (buttonElement) {
            // on click of `button` element
            buttonElement.onclick = () => {
                // get content `div` element
                const contentElement = document.getElementById("user_context_actions__content");
                // get the `button` element's verb text
                const buttonVerb = buttonElement.querySelector(".user_context_actions__content_toggle__verb");
                // toggle the visible state of the corresponding
                // content `div` element, and change it's text accordingly
                if (!contentElement.hasAttribute("open")) {
                    contentElement.setAttribute("open", "");
                    buttonElement.setAttribute("open", "");
                    buttonVerb.innerText = "Collapse";
                } else {
                    contentElement.removeAttribute("open");
                    buttonElement.removeAttribute("open");
                    buttonVerb.innerText = "Expand";
                }
            };
        }
    },
};
