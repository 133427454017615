module.exports = {
    /**
     * download_package_images
     * Enables downloading of images in package view page.
     */
    init: () => {
        function downloadAllImages () {
            const images = document.querySelectorAll(".package_detail__assets_item_image_asset");
            let i = 0;
            const timer = setInterval(() => {
                if (i < images.length) {
                    // check if image is not empty
                    if (images[i].src && images[i].src !== window.location.href) {
                        const link = document.createElement("a");
                        link.href = images[i].src;
                        link.download = images[i].alt || images[i].src.split("/").pop();

                        // open image in new tab when not https
                        // this is a fix for localhost
                        if (images[i].src.split(":")[0] != "https") {
                            link.target = "_blank";
                        }
                        link.click();
                        link.remove();
                    }
                    i++;
                } else {
                    clearInterval(timer);
                }
            }, 500);
        }

        document.querySelectorAll(".download_all_images").forEach(btn => {
            btn.addEventListener("click", (e) => {
                e.preventDefault();
                downloadAllImages();
            });
        });
    }
};
