module.exports = {
    // when user interacts with details element, close any open details
    // elements other than the target element
    // For large viewports account options is in a div rather than details, so this element is
    // targeted too if it exists
    init: () => {
        // Account options div elements
        const accountOptionsButtonClass  = ".global_navigation__account_options_menu_toggle--is_in_div_element";
        const accountOptionsButton       = document.querySelector(accountOptionsButtonClass);
        const accountOptionsNavListClass = ".global_navigation__account_options_menu_list--is_in_div_element";
        const accountOptionsNavList      = document.querySelector(accountOptionsNavListClass);

        // get every details element
        const detailsElements = Array.from(document.querySelectorAll("details"));

        // If account options contained within a div, add list to the array too
        if (accountOptionsButton) {
            detailsElements.push(accountOptionsNavList);
        }

        // add listeners for interaction with all details elements found
        detailsElements.forEach((targetDetailsElement) => {
            targetDetailsElement.addEventListener("click", () => {
                // remove "open" attribute from any open details elements
                // aside from the target element
                detailsElements.forEach((detailsElement) => {
                    if (detailsElement !== targetDetailsElement) {
                        detailsElement.removeAttribute("open");
                    }
                });
            });
        });
    },
};
