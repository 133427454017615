module.exports = {
    // copy authentication token id to the clipboard
    // when user interacts with the copy button
    init: () => {
        const authenticationTokenID = "authentication_token__token_id";
        const authenticationToken   = document.getElementById(authenticationTokenID);
        const copyButtonID          = "authentication_token__copy_button";
        const copyButton            = document.getElementById(copyButtonID);
        function copyToken () {
            authenticationToken.select();
            document.execCommand("copy");
            const initialButtonText = copyButton.innerText;
            copyButton.innerText = "Copied!";
            setTimeout(() => {
                copyButton.innerText = initialButtonText;
            }, 4000);
        }
        if (authenticationToken && copyButton) {
            copyButton.onclick = copyToken;
        }
    },
};
