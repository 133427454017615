module.exports = {
    init: () => {
        /*
            give each alert element a `display` value of `none`
            when user interacts with that alert's close button
        */
        // get all `alert` elements
        const alertElements = Array.from(document.getElementsByClassName("alert"));
        // if `alert` elements are present
        if (alertElements) {
            // for each `alert` element
            alertElements.forEach((alertElement) => {
                // get this `alert` element's close button
                const closeButton = alertElement.querySelector(".alert__close_button");
                // on click of this `alert` element's close button
                closeButton.onclick = () => {
                    // give this `alert` element a `display` value of `none`
                    alertElement.style.display = "none";
                };
            });
        }
    },
};
