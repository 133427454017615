module.exports = {
    /*
        close account options open details element,
        when user interacts with anything outside of the element
    */
    init: () => {
        const accountOptionsClass = ".page_uuid__details";
        const accountOptions      = document.querySelector(accountOptionsClass);
        if (accountOptions) {
            const detailsAttribute = "open";
            const mutationObserver = new MutationObserver((mutations) => {
                mutations.forEach((mutation) => {
                    if (mutation.attributeName === detailsAttribute && accountOptions.hasAttribute(detailsAttribute)) {
                        accountOptions.onclick = (click) => {
                            if (click.target === accountOptions) {
                                accountOptions.removeAttribute(detailsAttribute);
                            }
                        };
                        document.onkeydown = (pressedKey) => {
                            pressedKey = pressedKey || window.event;
                            if (pressedKey.key === "Escape") {
                                accountOptions.removeAttribute(detailsAttribute);
                            }
                        };
                    }
                });
            });
            mutationObserver.observe(accountOptions, {
                attributes: true,
            });
        };
    },
};
