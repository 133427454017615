module.exports = {
    init: () => {
        /*
          toggle_elem
          allows a value on on element to define if a second element is visible

          set id="toggle_trigger_x" on your trigger element,
          and trigger_value="z" defines the value to match to trigger the event
          set id="toggle_target_x" on the element you want to show or hide
        */
        let triggers = document.querySelectorAll('*[id^="toggle_trigger_"]');  // eslint-disable-line quotes
        if (triggers) {
            triggers.forEach((triggerElem) => {
                triggerElem.addEventListener("change", (event) => {
                    let triggerValue = event.target.getAttribute("trigger_value");
                    if (!triggerValue) {
                        console.warn(
                            "Null value used as triggerValue for toggleElem"
                        );
                    };
                    // trigger
                    if (event.target.value === triggerValue) {
                        // select all matching trigger targets
                        let targets = document.querySelectorAll(
                            `*[id^="${event.target.id.replace(
                                "toggle_trigger_", "toggle_target_"
                            )}"]`
                        );

                        if (targets) {
                            targets.forEach((target) => {
                                if (target.style.display !== "none") {
                                    target.style.display = "none";
                                } else {
                                    target.style.removeProperty("display");
                                }
                            });
                        }
                    } else {
                        // if target value does not match, set display to none
                        let targets = document.querySelectorAll(
                            `*[id^="${event.target.id.replace(
                                "toggle_trigger_", "toggle_target_"
                            )}"]`
                        );

                        if (targets) {
                            targets.forEach((target) => {
                                target.style.display = "none";
                            });
                        }
                    }
                });
            });
        }
    },
};
